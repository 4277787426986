import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "elements/Button"

import { AppContext } from "context/AppContext"
import { getSignedInUser } from "../../Auth/services/user"

const CompleteProfileModal = () => {
  const { userData } = getSignedInUser()
  const { dispatch } = useContext(AppContext)

  const handleClick = () => {
    const { validity } = userData.domain

    if (validity) {
      navigate("/enrollment")
    } else {
      dispatch({ type: "HIDE_MODAL" })
      navigate("/access-code")
    }
  }
  return (
    <div className="has-text-left">
      <p className=" mb-1">
        You have to complete your profile before we can set you up with a mental
        health professional. Please click the button below to start.
      </p>
      <center>
        <Button className="is-primary" onClick={handleClick}>
          Complete Profile
        </Button>
      </center>
    </div>
  )
}

export default CompleteProfileModal
