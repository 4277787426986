import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"

import Layout from "layout"
import Container from "layout/Container"
import Hero from "layout/Hero"
import EditProfileModal from "layout/EditProfileModal"
import Video from "elements/Video"
import Hexagon from "../Layout/Hexagon"

import ProgramDescriptions from "./ProgramDescriptions"
import programCardContent from "./utils/programCardContent"
import CompleteProfileModal from "../Consultations/Home/CompleteProfileModal"
import HowItWorks from "../Elements/HowItWorks"
import styles from "./utils/staticPages.module.scss"
import howProgramWorks from "./utils/howProgramWorks.json"
import phThumbnail from "../../../static/images/ph_thumbnail.png"
import mhThumbnail from "../../../static/images/mh_thumbnail.png"

import { isBrowser } from "../../services/general"
import {
  hasSignedInUser,
  getSignedInUser,
  hasRoles,
  isProfileComplete,
} from "../Auth/services/user"

import { decodePermissions } from "../Auth/services/signin"
import { AppContext } from "../../context/AppContext"
import useServicesImages from "./hooks/useServicesImages"

import {
  GATSBY_PHYSICAL_HEALTH_VIDEO,
  GATSBY_MENTAL_HEALTH_VIDEO,
} from "gatsby-env-variables"
import classNames from "classnames"

const Home = (props) => {
  const [showAll, setShowAll] = useState(true)
  let { pageContext, location } = props
  const { dispatch } = useContext(AppContext)

  let userData, decodedPermissions, accessStatus
  useEffect(() => {
    if (isBrowser()) {
      sessionStorage.removeItem("medstaffData")
      let isShowAll = JSON.parse(sessionStorage.getItem("accessRule"))?.showAll
      if (!!isShowAll)
        setShowAll(JSON.parse(sessionStorage.getItem("accessRule"))?.showAll)
    }
  }, [])

  if (hasSignedInUser()) {
    userData = getSignedInUser().userData
    accessStatus = getSignedInUser()?.accessRule?.status
      ? getSignedInUser()?.accessRule?.status
      : "active"
    decodedPermissions = decodePermissions(userData?.permissions)
  }

  useEffect(() => {
    if (isBrowser()) {
      dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
      if (hasSignedInUser() && !hasRoles()) {
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            heading: "Complete your profile",
            isCard: true,
            headerClass: `has-text-info has-background-info-light has-text-weight-bold header-size-text`,
            content: <CompleteProfileModal />,
          },
        })
      }

      if (
        hasSignedInUser() &&
        !isProfileComplete() &&
        location?.state?.fromRedirect
      ) {
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            heading: "Complete your profile",
            isCard: true,
            headerClass: `has-text-info has-background-info-light has-text-weight-bold header-size-text`,
            content: <EditProfileModal />,
          },
        })
      }
    }
  }, [dispatch, location.state])

  const handleOnClick = ({ name, value, url }) => {
    sessionStorage.setItem("programChosen", name)

    switch (true) {
      case (hasSignedInUser() && !hasRoles()) ||
        (hasSignedInUser() && !isProfileComplete()):
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            heading: "Complete your profile",
            isCard: true,
            headerClass: `has-text-info has-background-info-light has-text-weight-bold header-size-text`,
            content: <CompleteProfileModal />,
          },
        })
        break

      case !!hasSignedInUser():
        let valid =
          decodedPermissions.includes(value.toString().slice(-5)) &&
          accessStatus?.toLowerCase() === "active"

        if (valid) {
          navigate(`/booking/${url}/intake`)
        } else {
          dispatch({
            type: "SHOW_MODAL",
            payload: {
              heading: "Company is not registered",
              isCard: true,
              headerClass: `has-text-info has-background-info-light has-text-weight-bold header-size-text`,
              content: (
                <>
                  Your company is currently not registered with MedGrocer's
                  {" " + name} Program. Email your HR to let them know that
                  you're interested in being eligible for this program.
                </>
              ),
            },
          })
        }

        break

      default:
        navigate("/verify-email")
        break
    }
  }

  return (
    <Layout
      display={{ helpCenterBanner: false, footer: true }}
      seoTitle="Teleconsult"
      pageContext={pageContext}
      isPrivate={true}
      polygonStyle="services"
    >
      <Hero>
        <Container isCentered desktop={10} fullhd={8}>
          <div className="pt-6 pb-4">
            <div className="columns is-desktop">
              <div className="column is-one-half is-flex is-align-items-center">
                <div className="mx-1">
                  <h1 className="mt-1 mb-0 has-text-primary no-wrap">
                    Delivering healthcare
                  </h1>
                  <h1 className="mt-0 mb-1 has-text-primary">everywhere</h1>
                  <h3 className={`mb-1 has-text-weight-normal`}>
                    Choose a schedule that works for you. Teleconsults are{" "}
                    <strong className="has-text-secondary">
                      free for employees
                    </strong>{" "}
                    of our corporate partners.
                  </h3>
                </div>
              </div>
              <div className="column is-one-half">
                <div
                  className={classNames(
                    "columns is-flex-direction-column",
                    styles[`buttonContainer`]
                  )}
                >
                  {programCardContent.flatMap((item, index) => {
                    if (
                      showAll ||
                      decodedPermissions?.includes(
                        item?.value?.toString().slice(-5)
                      )
                    )
                      return (
                        <button
                          onClick={(e) =>
                            handleOnClick({
                              name: item.name,
                              value: item.value,
                              url: item.url || "mind",
                            })
                          }
                          key={index}
                          className={classNames(
                            "column is-full",
                            styles[`programButton`]
                          )}
                        >
                          <ProgramDescriptions
                            img={item.img}
                            title={item.name}
                            details={item.details}
                          />
                        </button>
                      )
                  })}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Hero>
      {/* <ProgramDescriptions /> */}
      <HowItWorks
        howProgramWorks={howProgramWorks}
        useImages={useServicesImages}
      />
      <div className="is-relative pb-1-mobile pb-7">
        <h2 className="has-text-centered has-text-primary mt-6 mb-1-mobile mb-2 py-2 px-2 px-4-mobile line-height-1">
          Learn how our programs <span>can help you</span>
        </h2>
        <Hexagon>
          {GATSBY_PHYSICAL_HEALTH_VIDEO ? (
            <div className="has-text-centered pb-3-mobile py-6 py-1-mobile">
              <h2 className="has-text-primary mb-2">Physical Health</h2>
              <Video
                videoSrcURL={GATSBY_PHYSICAL_HEALTH_VIDEO}
                videoTitle="Teleconsult Service"
                thumbnail={phThumbnail}
              />
            </div>
          ) : null}
          {GATSBY_MENTAL_HEALTH_VIDEO ? (
            <div className="has-text-centered pb-3-mobile py-6 py-1-mobile">
              <h2 className="has-text-primary mb-2">Mental Health</h2>
              <Video
                videoSrcURL={GATSBY_MENTAL_HEALTH_VIDEO}
                videoTitle="Teleconsult Service"
                thumbnail={mhThumbnail}
              />
            </div>
          ) : null}
        </Hexagon>
      </div>
    </Layout>
  )
}

export default Home
