import React from "react"
import styles from "./utils/staticPages.module.scss"
import arrowRight from "../../../static/images/icons/arrow_right.svg"

const ProgramDescriptions = ({ img, title, details }) => {
  return (
    <div className="card columns is-flex is-flex-direction-row is-overflow-hidden py-3 py-0-mobile mini-card has-text-left px-1-mobile px-2 mb-2">
      <div className="py-2-mobile is-relative pr-2-mobile pr-3">
        <h2 className="pb-1 is-inline-block has-text-weight-bold	pt-0-mobile has-border-bottom-light">
          {title}
        </h2>
        <p className="mt-1 has-text-left">{details}</p>
      </div>
      <img src={arrowRight} alt="" className={styles["arrow_icon"]} />
      <img src={img} alt="mh-services-icon" className={styles["mh_icon"]} />
    </div>
  )
}

export default ProgramDescriptions
