import { useStaticQuery, graphql } from "gatsby"

const useServicesImages = () => {
  const data = useStaticQuery(graphql`
    {
      book: file(relativePath: { eq: "icons/mh__1_book.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bookMobile: file(relativePath: { eq: "icons/mh__1_book.png" }) {
        childImageSharp {
          fixed(width: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      confirm: file(relativePath: { eq: "icons/mh__2_confirm.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      confirmMobile: file(relativePath: { eq: "icons/mh__2_confirm.png" }) {
        childImageSharp {
          fixed(width: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      attend: file(relativePath: { eq: "icons/mh__3_attend.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      attendMobile: file(relativePath: { eq: "icons/mh__3_attend.png" }) {
        childImageSharp {
          fixed(width: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      arrow: file(relativePath: { eq: "icons/triangle__grey.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      medgrocer: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      physicalHealth: file(relativePath: { eq: "stethoscope_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 82) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mentalHealth: file(relativePath: { eq: "icons/mh__home_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 82) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      polygonOrange: file(relativePath: { eq: "icons/polygon_orange.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      polygonOrangeMobile: file(relativePath: { eq: "icons/polygon_orange_mobile.png" }) {
        childImageSharp {
          fixed(width: 210) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      polygonTeal: file(relativePath: { eq: "icons/polygon_teal.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      polygonTealMobile: file(relativePath: { eq: "icons/polygon_teal_mobile.png" }) {
        childImageSharp {
          fixed(width: 110) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mhHead: file(relativePath: { eq: "icons/mh_head.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mhHeadColored: file(relativePath: { eq: "icons/mh__head.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mhTelescope: file(relativePath: { eq: "icons/mh_telescope.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mhTelescopeColored: file(relativePath: { eq: "icons/mh__telescope.png" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mhHexagon: file(relativePath: { eq: "icons/mh_hexagon.png" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mhHexagonMobile: file(relativePath: { eq: "icons/mh_hexagon.png" }) {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useServicesImages
