import {
  GATSBY_MENTAL_HEALTH_PROGRAM_CODE,
  GATSBY_PHYSICAL_HEALTH_PROGRAM_CODE,
  // GATSBY_ONSITE_WELLNESS_PROGRAM_CODE,
} from "gatsby-env-variables"
import mhHead from "../../../../static/images/icons/mh_head.png"
import mhTelescope from "../../../../static/images/icons/mh_telescope.png"

export default [
  {
    name: "Mental Health",
    details: "Engage with licensed mental health professionals on a 24/7 appointment-based schedule.",
    img: mhHead,
    url: "mind",
    value: Buffer.from(GATSBY_MENTAL_HEALTH_PROGRAM_CODE),
  },
  {
    name: "Physical Health",
    details: "Book a teleconsult with licensed doctors for a general check up, prescription renewal, or return to office clearance.",
    img: mhTelescope,
    url: "body",
    value: Buffer.from(GATSBY_PHYSICAL_HEALTH_PROGRAM_CODE),
  },
  // {
  //   img: "onsiteWellness",
  //   name: "Onsite Wellness",
  //   url: "coaching",
  //   value: Buffer.from(GATSBY_ONSITE_WELLNESS_PROGRAM_CODE),
  // },
]
